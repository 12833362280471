export default function loadDashboardApi(api: Prime.ApiRequest){

  const getRewardsBalance = (): Promise<Prime.RewardsBalance> => {
    return api('/api/dashboard/reward-balance')
  }

  const getRewardsGiven = (): Promise<Prime.RewardsGiven> => {
    return api('/api/dashboard/rewards-given')
  }

  const getRewardsReceived = (): Promise<Prime.RewardsReceived> => {
    return api('/api/dashboard/rewards-received')
  }

  const getRewardActions = (): Promise<Prime.RewardAction> => {
    return api('/api/dashboard/reward-actions')
  }

  const getDailyRewardActions = (days: number, startDate: string, endDate: string): Promise<Prime.RewardDailyAction[]> => {
    return api(`/api/dashboard/daily-reward-actions?startDate=${startDate}&endDate=${endDate}`)
  }

  const getDailyRewardAmounts = (days: number, startDate: string, endDate: string): Promise<Prime.RewardAmount[]> => {
    return api(`/api/dashboard/daily-reward-amounts?startDate=${startDate}&endDate=${endDate}`)
  }

  const getRewardsReceivedToday = (): Promise<Prime.ReceivedToday> => {
    return api('/api/dashboard/rewards-received-today')
  }

  const getRewardsRecentActivity = (): Promise<Prime.TeamActivity[]> => {
    return api('/api/dashboard/reward-recent-activity')
  }

  const getTeamInfo = (): Promise<any> => {
    return api('/api/dashboard/teams')
  }
  
  const apis = {
    getRewardsBalance,
    getRewardsGiven,
    getRewardsReceived,
    getRewardActions,
    getDailyRewardActions,
    getDailyRewardAmounts,
    getRewardsReceivedToday,
    getRewardsRecentActivity,
    getTeamInfo
  }

  return apis
}
