import teamApi from './teamApi'
import dashboardApi from './dashboardApi'
import memberApi from './memberApi'
import authApi from './authApi'
import rewardApi from './rewardApi'
import profileApi from './profileApi'
import loadAccountApi from './accountApi'
import loadFeatureIdeaApi from './featureIdeaApi'
import insightsApi from './insightsApi'
import threadApi from './threadApi'
import adminApi from './adminApi'
import agreementApi from './agreementApi'

function loadApi(api: Prime.ApiRequest) {

  // Get Pre Signed URL
  const getSignedUrl = async (type: string): Promise<Prime.PreSignedUrl> => {
    return api(`/api/s3upload/presigned-url/${type}`)
  }

  // UploadFile
  const uploadFile = async (url: string, data: Blob): Promise<void> => {
    const headers = {
      'Content-Type': 'multipart/form-data'
    }
    await fetch(url, {
      method: 'PUT',
      body: data,
      headers
    })
  }

  const apis = {
    getSignedUrl,
    uploadFile
  }
  
  return apis
}

export default function apis(api: Prime.ApiRequest) {
  const apis = {
    ...loadApi(api),
    ...authApi(api),
    ...memberApi(api),
    ...teamApi(api),
    ...dashboardApi(api),
    ...rewardApi(api),
    ...profileApi(api),
    ...loadAccountApi(api),
    ...loadFeatureIdeaApi(api),
    ...insightsApi(api),
    ...threadApi(api),
    ...adminApi(api),
    ...agreementApi(api)
  }
  return apis
}