export default function loadMemberApi(api: Prime.ApiRequest) {
  // Get Member's Team Invites
  const getMemberInvites = (): Promise<Prime.TeamInvite[]> => {
    return api('/api/members/invites')
  }

  // Get Member's Settings
  const getMemberSettings = (): Promise<Prime.MemberSettings> => {
    return api('/api/members/settings')
  }

  const acceptMemberInvite = (teamInviteId: number) => {
    return api('/api/members/invites', {
      teamInviteId
    })
  }

  const getMemberAvatarUrl = (): Promise<Prime.Avatar> => {
    return api('/api/members/settings/avatar')
  }

  const getMemberPoints = (): Promise<Prime.Points> => {
    return api('/api/points')
  }

  const apis = {
    getMemberInvites,
    acceptMemberInvite,
    getMemberSettings,
    getMemberAvatarUrl,
    getMemberPoints
  }
  
  return apis
}