const local: Prime.RuntimeEnvironment = {
  firebaseApiKey: 'AIzaSyDYi0dRxYJZDGvda5uL5OSp4OPIAVFLLdM',
  firebaseAuthDomain: 'nerdprime-dev.firebaseapp.com',
  firebaseProjectId: 'nerdprime-dev',
  firebaseAppId: '1:763825453756:web:6961f8e88650f93379eeb9',
  primeUrl: 'http://localhost:6001',
  // primeUrl: 'http://localhost:5293',
  recaptchaSiteKey: '6LdFmRsgAAAAALnRkpnLH3qPxlzsoQ8icWYX-UPo',
  assetsUrl: 'https://download.dev.nerdunited.com',
  slackRedirectUri: 'https://crazy-bears-cough-204-225-28-162.loca.lt/teams/integrations/slack',
  slackClientId: '4147584566611.4147728081170',
  sentryDsn: '',
  sentrySampling: 0,
  analyticsKey: 'G-QXMN6Q8QL1',
  pendoKey: '',
  zendeskKey: 'ed339b40-aa30-489d-b8b0-b97e83de11e9',
  launchDarklyClientId: '632a28bdad7e18114f237ad0'
}
export default local