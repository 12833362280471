export default function loadInsightsApi(api: Prime.ApiRequest) {

  // Get All User Teams
  const getCategorySentiment = async (teamId: number): Promise<Prime.CategorySentiment> => {
    return api(`/api/team/${teamId}/sentiment/categories`)
  }
  
  const getOverallSentiment = async (teamId: number): Promise<Prime.OverallSentiment> => {
    return api(`/api/team/${teamId}/sentiment/overall`)
  }

  const getCurrentAchievements = async (teamId: number, startDate: string, endDate: string): Promise<Prime.CurrentAchievements[]> => {
    return api(`/api/teams/${teamId}/achievements/current?startDate=${startDate}&endDate=${endDate}`)
  }

  const getStakeholderInsights = async (orgId?: number): Promise<Prime.StakeholderInsights> => {
    return api(`/api/member/stakeholder${orgId ? `?organizationId=${orgId}` : ''}`)
  }

  const getSentimentDrilldown = async (teamId: number): Promise<Prime.SentimentDrilldownQuestion[]> => {
    return api(`/api/team/${teamId}/sentiment/drilldown`)
  }


  const apis = {
    getCategorySentiment,
    getOverallSentiment,
    getCurrentAchievements,
    getStakeholderInsights,
    getSentimentDrilldown
  }
  
  return apis
}
