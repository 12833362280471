const development: Prime.RuntimeEnvironment = {
  firebaseApiKey: 'AIzaSyAWMz6AypLd8_bNsUc02yPElvojsAgU20o',
  firebaseAuthDomain: 'nerdunited-dev-7a057.firebaseapp.com',
  firebaseProjectId: 'nerdunited-dev-7a057',
  firebaseAppId: '1:1007282859724:web:76d32a89f15c963460dc69',
  primeUrl: 'https://api.dev.prime.nerdunited.com/default',
  recaptchaSiteKey: '6Lcby9EgAAAAAPFgTTlZQ9vdM4vWF8ki6idXr60J',
  assetsUrl: 'https://download.dev.nerdunited.com',
  slackRedirectUri: 'https://dev.prime.nerdunited.com/teams/integrations/slack',
  slackClientId: '3975005428118.3979044599686',
  sentryDsn: 'https://0456116137cc4716bc993653a5de37eb@o1344175.ingest.sentry.io/6619834',
  sentrySampling: 1.0,
  analyticsKey: 'G-QXMN6Q8QL1',
  pendoKey: '',
  zendeskKey: 'ed339b40-aa30-489d-b8b0-b97e83de11e9',
  launchDarklyClientId: '632a28bdad7e18114f237ad0'
}
export default development