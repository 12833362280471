import '../styles/global.css'
import { getCookie } from 'cookies-next'
import * as React from 'react'
import Head from 'next/head'
import App, { AppProps, AppContext as Context } from 'next/app'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { CacheProvider, EmotionCache } from '@emotion/react'
import theme from '../src/theme'
import createEmotionCache from '../src/createEmotionCache'
import AppContext from '../src/stores/appContext'
import { initializeApp, getApps } from 'firebase/app'
import environment from '../src/utils/environment'
import logger from '../src/utils/logger'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { beginLogs } from '../src/utils/services/appLogs'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { ComponentType, FunctionComponent } from 'react'

const {
  firebaseApiKey,
  firebaseAuthDomain,
  firebaseProjectId,
  firebaseAppId,
  launchDarklyClientId,
}  = environment as Prime.RuntimeEnvironment

if(process.env.SERVER_ENV)logger.info([ 'Initializing Environment: ', environment ])

// Firebase
const config = {
  apiKey: firebaseApiKey,
  authDomain: firebaseAuthDomain,
  projectId: firebaseProjectId,
  appId: firebaseAppId
}

if(getApps().length === 0){
  initializeApp(config)
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface PrimeAppProps extends AppProps {
  emotionCache?: EmotionCache
  Component: Prime.NextPageWithLayout,
  session: any
}

function PrimeApp(props: PrimeAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps, session } = props
  const getLayout = Component.getLayout ?? ((page: React.ReactElement) => page)
  const [ loading, setLoading ] = React.useState(true)

  const auth = getAuth()
  if(!auth.currentUser){
    onAuthStateChanged(auth, user => {
      setLoading(false)
    })
  }

  React.useEffect(()=>{
    async function showVersion(){
      await beginLogs()

    }
    showVersion()
  }, [])
  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Nerd Prime</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {!loading && 
          <AppContext session={session}>
            {getLayout(<Component {...pageProps} />)}
          </AppContext>
        }
      </ThemeProvider>
    </CacheProvider>
  )
}

PrimeApp.getInitialProps = async (context: Context) => {
  const cntx = await App.getInitialProps(context)
  const { ctx: { req, res } } = context
  const session = getCookie('auth', { req, res })

  return {
    ...cntx,
    ...(session && { session })
  }
}

export default withLDProvider({
  clientSideID: launchDarklyClientId!,
})(PrimeApp as unknown as ComponentType<{}>)