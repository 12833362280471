const production: Prime.RuntimeEnvironment = {
  firebaseApiKey: 'AIzaSyB-mls_rJVB0iMUKAwFFObC-a35-lWsGyo',
  firebaseAuthDomain: 'nerdprime-prod.firebaseapp.com',
  firebaseProjectId: 'nerdprime-prod',
  firebaseAppId: '1:469681885040:web:34140233c420372d3349b5',
  primeUrl: 'https://api.nerdunited.com/default',
  recaptchaSiteKey: '6LdntKAhAAAAAKSnemCa1ksCczwO6aadv8Oyrs1b',
  assetsUrl: 'https://download.nerdunited.com',
  slackRedirectUri: 'https://prime.nerdunited.com/teams/integrations/slack',
  slackClientId: '3014184694567.3985906760562',
  sentryDsn: 'https://0456116137cc4716bc993653a5de37eb@o1344175.ingest.sentry.io/6619834',
  sentrySampling: 1.0,
  analyticsKey: 'G-QXMN6Q8QL1',
  pendoKey: '178f58c3-c596-42ca-6d11-3911ed8a0a6e',
  zendeskKey: 'ed339b40-aa30-489d-b8b0-b97e83de11e9',
  launchDarklyClientId: '632a28bdad7e18114f237ad1'
}
export default production