import pino from 'pino'

const config = {
  serverUrl: process.env.NEXT_PUBLIC_LOG_URL,
  env: process.env.NODE_ENV
}

const pinoConfig: pino.LoggerOptions = {
  browser: {
    asObject: true
  }
}

if (config.serverUrl) {
  pinoConfig.browser!.transmit = {
    level: 'info',
    send: (level, logEvent) => {
      const msg = logEvent.messages[0]

      const headers = {
        type: 'application/json'
      }
      let blob = new Blob([ JSON.stringify({ msg, level }) ], headers)
      // this endpoint should match when we have a place to send them
      navigator.sendBeacon(`${config.serverUrl}/log`, blob)
    }
  }
}

const logger = pino(pinoConfig)

export default logger