import { omitBy, isEmpty } from 'lodash'
export default function threadApi(api: Prime.ApiRequest) {

  const getTeamThreads = (teamId: number, statusId: string = '', startDate: string = '', endDate: string = ''): Promise<Prime.TeamDiscussion> => {
    const params = new URLSearchParams(omitBy({ teamDiscussionPostStatusId: statusId, startDate, endDate }, isEmpty))
    return api(`/api/teams/${teamId}/discussion/posts${params && '?' + params.toString()}`)
  }

  const getTeamThread = (teamId: number, teamDiscussionPostId: number): Promise<Prime.TeamDiscussion> => {
    return api(`/api/teams/${teamId}/discussion/post/${teamDiscussionPostId}`)
  }

  const updateThreadPost = (teamId: number, teamDiscussionPostId: number, title: string, post: string, status?: number): Promise<void> => {
    return api(`/api/teams/${teamId}/discussion/post/${teamDiscussionPostId}`, {
      title,
      post,
      teamDiscussionPostStatusId: status
    }, { method: 'PUT' })
  }

  const createThreadTag = (teamId: number, tag: string, description: string, sortOrder: number, isActive: boolean): Promise<void> => {
    return api(`/api/teams/${teamId}/discussion/tag`, {
      teamId,
      tag,
      description,
      sortOrder,
      isActive
    })
  }

  const getThreadTags = (teamId: number): Promise<Prime.TeamDiscussionTag[]> => {
    return api(`/api/teams/${teamId}/discussion/tags`)
  }

  const getAllThreadTags = (teamId: number): Promise<Prime.TeamDiscussionTag[]> => {
    return api(`/api/teams/${teamId}/discussion/all-tags`)
  }

  const updateThreadTag = (teamId: number, teamDiscussionTagId: number, tag: string, description: string, sortOrder: number, isActive: boolean): Promise<void> => {
    return api(`/api/teams/${teamId}/discussion/tag/${teamDiscussionTagId}`, {
      tag,
      description,
      sortOrder,
      isActive
    }, { method: 'PUT' })
  }

  const updateThreadTagIsActive = (teamId: number,teamDiscussionTagId: number, isActive: boolean): Promise<void> => {
    return api(`/api/teams/${teamId}/discussion/tag/${teamDiscussionTagId}/${isActive}`, {
    }, { method: 'PUT' })
  }

  const updateThreadTagSortOrder = (teamId: number,teamDiscussionTagId: number, sortOrder: number): Promise<void> => {
    return api(`/api/teams/${teamId}/discussion/tag/${teamDiscussionTagId}/${sortOrder}`, {
    }, { method: 'PUT' })
  }

  const createThreadPost = (teamId: number, teamDiscussionTagId: number, title: string, post: string): Promise<void> => {
    return api(`/api/teams/${teamId}/discussion/post`, {
      teamId,
      teamDiscussionTagId,
      title,
      post,
    })
  }

  const archiveThreadPost = (teamId: number, teamDiscussionPostId: number): Promise<void> => {
    return api(`/api/teams/${teamId}/discussion/post/${teamDiscussionPostId}/archive`, {}, { method: 'DELETE' })
  }

  const createThreadReply = (teamId: number, teamDiscussionPostId: number, title: string, reply: string): Promise<void> => {
    return api(`/api/teams/${teamId}/discussion/reply`, {
      teamId,
      teamDiscussionPostId,
      title,
      reply
    })
  }

  const updateThreadReply = (teamId: number, teamDiscussionReplyId: number, reply: string, title: string = ''): Promise<void> => {
    return api(`/api/teams/${teamId}/discussion/reply/${teamDiscussionReplyId}`, { title, reply }, { method: 'PUT' })
  }

  const archiveThreadReply = (teamId: number, teamDiscussionReplyId: number): Promise<void> => {
    return api(`/api/teams/${teamId}/discussion/reply/${teamDiscussionReplyId}/archive`, {}, { method: 'DELETE' })
  }
  
  const createThreadReaction = (teamId: number, teamDiscussionPostId: number, reaction: string): Promise<void> => {
    return api(`/api/teams/${teamId}/discussion/post-reaction`, {
      teamId,
      teamDiscussionPostId,
      reaction
    })
  }
  
  const deleteThreadPostReaction = (teamId: number, postReactionId: number): Promise<void> => {
    return api(`/api/teams/${teamId}/discussion/post-reaction/${postReactionId}`, {}, { method: 'DELETE' })
  }

  const createThreadReplyReaction = (teamId: number, teamDiscussionReplyId: number, reaction: string): Promise<void> => {
    return api(`/api/teams/${teamId}/discussion/reply-reaction`, {
      teamId,
      teamDiscussionReplyId,
      reaction
    })
  }

  const deleteThreadReplyReaction = (teamId: number, replyReactionId: number): Promise<void> => {
    return api(`/api/teams/${teamId}/discussion/reply-reaction/${replyReactionId}`, {}, { method: 'DELETE' })
  }
  
  const getThreadStatuses = (teamId: number): Promise<Prime.TeamDiscussionPostStatus[]> => {
    return api(`/api/teams/${teamId}/discussion/status`)
  }

  const updatePostStatus = (teamId: number, statusId: number, status: string, isActive: number): Promise<void> => {
    return api(`/api/teams/${teamId}/discussion/status/${statusId}`, { status, isActive }, { method: 'PUT' })
  }

  const apis = {
    getTeamThreads,
    getTeamThread,
    updateThreadPost,
    createThreadTag,
    getThreadTags,
    getAllThreadTags,
    updateThreadTag,
    updateThreadTagIsActive,
    updateThreadTagSortOrder,
    createThreadPost,
    archiveThreadPost,
    createThreadReply,
    updateThreadReply,
    archiveThreadReply,
    createThreadReaction,
    deleteThreadPostReaction,
    createThreadReplyReaction,
    deleteThreadReplyReaction,
    getThreadStatuses,
    updatePostStatus
  }

  return apis
}