import production from './production'
import staging from './staging'
import development from './development'
import local from './local'

export const isBrowser = typeof window !== 'undefined'
const SERVER_ENV: Prime.Environment = process.env.SERVER_ENV as Prime.Environment || 'local' 

const environmentHostMap: Prime.HostMap = {
  'localhost:3000': 'local',
  'localhost': 'local',
  'dev.prime.nerdunited.com': 'development',
  'stage.prime.nerdunited.com': 'staging',
  'prime.nerdunited.com': 'production',
}

const environments: Prime.Environments = {
  production,
  staging,
  development,
  local,
}


/**
 * Determines the environment based on the domain of the site. If no match, returns local
 */
export const getEnvironment = (host = window.location.host): Prime.Environment => {
  return environmentHostMap[host] || SERVER_ENV
}

/**
 * Returns the runtime environment variables based on the domain of the site
 */
const getRuntimeEnvironment = (host = window.location.host): Prime.RuntimeEnvironment => {
  const currentEnv = getEnvironment(host)
  return environments[currentEnv]
}

/**
 * The same as getEnvironment, but safe for builds where the window object does not exist
 */
const getEnvironmentBuildSafe = (): Prime.RuntimeEnvironment => {
  if (isBrowser) return getRuntimeEnvironment()
  else return environments[SERVER_ENV]
}

export default getEnvironmentBuildSafe()