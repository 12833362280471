export default function loadMemberApi(api: Prime.ApiRequest) {

  const login = (token: string, authenticatorPin?: string) => {
    return api(`/api/auth/sign-in${authenticatorPin ? '?authenticatorPin=' + authenticatorPin : ''}`, {}, {
      auth: token,
      method: 'GET',
      hideSnackbar: !authenticatorPin
    })
  }

  // User
  const userSignUp = (fullName: string, email: string, password: string, callback?: string) => {
    let origin = 'http://localhost:3000'
    if(window){
      origin = window.location.origin
    }
    const ConfirmationLinkUrl = `${origin}${process.env.NEXT_PUBLIC_PRIME_VERIFY_EMAIL}${callback ? '?callback=' + callback : ''}`
    return api('/api/members', {
      fullName,
      ConfirmationLinkUrl,
      optInNewsletter: false,
      email,
      password
    })
  }

  //Send Email Verification
  const sendVerificationEmail = (email: string, callback?: string) => {
    let origin = 'http://localhost:3000'
    if(window){
      origin = window.location.origin
    }
    const ConfirmationLinkUrl = `${origin}${process.env.NEXT_PUBLIC_PRIME_VERIFY_EMAIL}${callback ? '?callback=' + callback : ''}`
    return api('/api/members/verification-email', {
      email,
      ConfirmationLinkUrl
    })
  }

  //Email Verification
  const verifyEmail = (confirmationcode: string) => {
    return api('/api/members/verification-email/confirm', {
      confirmationcode
    })
  }

  //Reset Password Email
  const sendPasswordResetEmail = (email: string) => {
    let origin = 'http://localhost:3000'
    if(window){
      origin = window.location.origin
    }
    return api('/api/auth/password-reset-email', {
      email,
      confirmationLinkUrl: `${origin}/reset-password`
    })
  }

  //Reset Password Email Confirmation
  const passwordResetEmailConfirm = (confirmationCode: string, password: string) => {
    return api('/api/auth/password-reset-email/confirm', {
      confirmationCode,
      password
    })
  }

  const apis = {
    login,
    userSignUp,
    sendVerificationEmail,
    verifyEmail,
    sendPasswordResetEmail,
    passwordResetEmailConfirm
  }
  return apis
}