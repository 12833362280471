export default function threadApi(api: Prime.ApiRequest) {

  const impersonate = (email: string): Promise<any> => {
    return api('/api/auth/impersonate', { email })
  }

  const getOrgs = (): Promise<Prime.Org[]> => {
    return api('/api/orgs')
  }

  const createOrg = (name: string, description: string, avatarUrl?: string): Promise<void> => {
    return api('/api/orgs', { name, description, avatarUrl })
  }

  const updateOrg = (id: number, name: string, description: string, avatarUrl?: string): Promise<void> => {
    return api(`/api/orgs/${id}`, { name, description, avatarUrl }, { method: 'PUT' })
  }

  const getOrg = (id: number): Promise<Prime.Org> => {
    return api(`/api/orgs/${id}`)
  }

  const deleteOrg = (id: number): Promise<void> => {
    return api(`/api/orgs/${id}`, {}, { method: 'DELETE' })
  }

  const getOrgTeams = (id: number): Promise<Prime.Team[]> => {
    return api(`/api/orgs/${id}/teams`)
  }

  const getOrgMembers = (id: number): Promise<Prime.OrgMember[]> => {
    return api(`/api/orgs/${id}/members`)
  }

  const addOrgMember = (id: number, memberId: number, roleId: number): Promise<void> => {
    return api(`/api/orgs/${id}/members/${memberId}/roles/${roleId}`, {}, { method: 'POST' })
  }

  const deleteOrgMember = (id: number, memberId: number, roleId: number): Promise<void> => {
    return api(`/api/orgs/${id}/members/${memberId}/roles/${roleId}`, {}, { method: 'DELETE' })
  }

  const searchMemberSettings = (term: string): Promise<Prime.MemberSettings[]> => {
    return api(`/api/members/settings/search?term=${term}`)
  }

  const getMemberOrgs = (): Promise<Prime.Org[]> => {
    return api('/api/members/orgs')
  }

  const apis = {
    impersonate,
    getOrgs,
    createOrg,
    updateOrg,
    getOrg,
    deleteOrg,
    getOrgTeams,
    getOrgMembers,
    addOrgMember,
    deleteOrgMember,
    searchMemberSettings,
    getMemberOrgs
  }

  return apis
}