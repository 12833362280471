import { makeAutoObservable } from 'mobx'

export class GlobalStore {
  reload: number = 0
  avatarUrl: string = ''
  displayName: string = ''
  mobileMenuOpen: boolean = false


  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  setAvatarUrl(avatarUrl: string){
    this.avatarUrl = avatarUrl
  }

  setDisplayName(displayName: string){
    this.displayName = displayName
  }

  forceRefresh(){
    this.reload++
  }
  
  reset(){
    this.reload = 0
  }

  setMobileMenuOpen(open: boolean){
    this.mobileMenuOpen = open
  }
}

export default new GlobalStore()