import * as Sentry from '@sentry/nextjs'
// Uses client side runtime environment variables
import runtimeEnvironment, { getEnvironment } from './src/utils/environment'

const { sentryDsn, sentry_sampling } = runtimeEnvironment
const environment = getEnvironment()

Sentry.init({
  dsn: sentryDsn,
  environment,
  tracesSampleRate: sentry_sampling || 1.0,
})
