import { getCookie, deleteCookie, setCookie } from 'cookies-next'
import { getAuth, signOut, signInWithEmailAndPassword, signInWithCustomToken, setPersistence, browserLocalPersistence, MultiFactorResolver, PhoneAuthProvider, RecaptchaVerifier, PhoneMultiFactorGenerator, Auth } from 'firebase/auth'
import logger from './logger'

const version = 1
export const RECAPTCHA_CONTAINER_ID = 'prime-recaptcha'

let auth: Auth
let phoneAuthProvider: PhoneAuthProvider
let recaptchaVerifier: RecaptchaVerifier

const ensureAuthInitialized = () => {
  if (!auth) {
    auth = getAuth()
    phoneAuthProvider = new PhoneAuthProvider(auth)
  }
}
const ensureRecaptchaInitialized = () => { 
  // need to do this separately as recaptcha div isn't available in the entire app
  if (!recaptchaVerifier) recaptchaVerifier = new RecaptchaVerifier(RECAPTCHA_CONTAINER_ID, { size: 'invisible' }, auth)
}

export const getToken = async () => {
  ensureAuthInitialized()
  const token = await auth.currentUser?.getIdToken()
  return token
}

export const logout = (logoutReason?: string) => {
  logger.info('Logout step 1: Ensure auth initialized')
  ensureAuthInitialized()
  logger.info('Logout step 2: sign out of firebase')
  signOut(auth).then(() => {
    logger.info('Logout step 3: delete auth')
    deleteAuth()
    if(typeof window !== 'undefined'){
      logger.info(`Logout step 4.5: set LogoutReason: ${logoutReason}`)
      if(logoutReason) window.localStorage.setItem('LogoutReason', logoutReason)
    }
    logger.info('Logout step 5: Redict to /')
    window.location.href = '/'
  })
}


export const sendPhoneMFACode = async (resolver: MultiFactorResolver) => {
  ensureAuthInitialized()
  ensureRecaptchaInitialized()
  const phoneInfoOptions = {
    multiFactorHint: resolver.hints[0],
    session: resolver.session
  }

  await recaptchaVerifier.verify()
  const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
  return verificationId
}

export const verifyPhoneMFACode = async (resolver: MultiFactorResolver, verificationId: string, verificationCode: string, api: Prime.Api) => {
  const cred = PhoneAuthProvider.credential(verificationId, verificationCode)
  const assertion = PhoneMultiFactorGenerator.assertion(cred)
  const userCredential = await resolver.resolveSignIn(assertion)
  
  const fbToken = await userCredential.user.getIdToken()
  // Augment JWT with Nerd/Prime claims
  const { token } = await api.login(fbToken)
  // Token/Claims augmentation
  await verifyAndSaveCustomToken(token)
}

export const verifyApp2FA = async (authenticatorPin: string, api: Prime.Api) => {
  // Augment JWT with Nerd/Prime claims
  const { token } = await api.login('', authenticatorPin)
  // Token/Claims augmentation
  await verifyAndSaveCustomToken(token)
}


export const deleteAuth = () => {
  logger.info('Logout step 4: deleting cookie')
  deleteCookie('auth', { secure: true })
}

export const getSession = (): Prime.Claims => {
  const session = getCookie('auth', { secure: true, sameSite: 'none' }) as string
  return session ? JSON.parse(session) : null
}

export const isTeamStakeholder = (teams: Prime.Team[]): boolean => {
  return teams.some((p)=>p.roles.includes('Stakeholder') || p.roles.includes('Owner') || p.roles.includes('Admin'))
}

export const saveSession = (auth: any) => {
  const { memberName, email, email_verified: emailVerified, memberId, roles, org_roles: orgRoles, impersonated } = auth
  setCookie('auth', JSON.stringify({ version, memberName, email, emailVerified, memberId, roles, orgRoles, impersonated }), { secure: true, sameSite: 'none' })
}


const verifyAndSaveCustomToken = async (token: string, isImpersonation: boolean = false) => {
  ensureAuthInitialized()
  logger.info('Login step 6: isImpersonation')
  if(isImpersonation){
    const currentToken = await getToken()
    setCookie('originToken', currentToken, { secure: true, sameSite: 'none' } )
  }
  // Sign back into Firebase with custom token
  logger.info('Login step 7: signInWithCustomToken')
  const customToken = await signInWithCustomToken(auth, token)
  logger.info('Login step 8: customToken.user.getIdTokenResult')
  const { claims } = await customToken.user.getIdTokenResult()
  logger.info('Login step 9: saveSession')
  saveSession(claims)
  logger.info('Login step 10: init auth')
  auth = getAuth()
}

export default function createAuth(api: Prime.Api, session: any){  
  const currentSession = session ? JSON.parse(session) : null
  ensureAuthInitialized()
  const login = async (email: string, password: string) => {

    logger.info('Login step 1: setPersistence')
    await setPersistence(auth, browserLocalPersistence)
    logger.info('Login step 2: signInWithEmailAndPassword')
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    )
    logger.info('Login step 3: userCredential.user.getIdToken()')
    const fbToken = await userCredential.user.getIdToken()
    // Augment JWT with Nerd/Prime claims
    logger.info('Login step 4: api.login')
    const { token } = await api.login(fbToken)
    // Token/Claims augmentation
    logger.info('Login step 5: verifyAndSaveCustomToken')
    await verifyAndSaveCustomToken(token)

  }
  
  const endImpersonate = async () => {
    const ogToken = getCookie('originToken', { secure: true, sameSite: 'none' }) as string
    deleteCookie('originToken', { secure: true })
    const { token } = await api.login(ogToken)
    await verifyAndSaveCustomToken(token)
  }

  const getClaims = () => {
    const session = getSession()
    if(session?.version !== version){
      logout('Session Expired')
    }
    return session ? session : currentSession
  }
  return {
    login,
    getClaims,
    endImpersonate,
    verifyAndSaveCustomToken
  }
}