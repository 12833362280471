export default function loadProfileApi(api: Prime.ApiRequest) {

  const getProfile = (memberId: number): Promise<Prime.MemberProfile> => {
    return api(`/api/members/${memberId}/profiles`)
  }
  const getProfileRewardsReceived = (memberId: number): Promise<Prime.RewardsReceived> => {
    return api(`/api/members/${memberId}/profiles/rewards/received`)
  }
  const getProfileRewardsGiven = (memberId: number): Promise<Prime.RewardsGiven> => {
    return api(`/api/members/${memberId}/profiles/rewards/given`)
  }
  const getProfileRewardsActions = (memberId: number, startDate: string, endDate: string): Promise<Prime.RewardDailyAction[]> => {
    return api(`/api/members/${memberId}/profiles/rewards/actions?startDate=${startDate}&endDate=${endDate}`)
  }
  const getProfileRewardsActionsTotal = (memberId: number): Promise<Prime.RewardAction> => {
    return api(`/api/members/${memberId}/profiles/rewards/actions/total`)
  }
  const getProfileTeams = (memberId: number): Promise<Prime.DashboardTeam[]> => {
    return api(`/api/members/${memberId}/profiles/teams`)
  }
  const getProfileRewardsBalance = (memberId: number, startDate: string, endDate: string): Promise<Prime.RewardBalanceTrend[]> => {
    return api(`/api/members/${memberId}/profiles/rewards/balances?startDate=${startDate}&endDate=${endDate}`)
  }

  const apis = {
    getProfile,
    getProfileRewardsReceived,
    getProfileRewardsGiven,
    getProfileRewardsActions,
    getProfileRewardsActionsTotal,
    getProfileTeams,
    getProfileRewardsBalance
  }

  return apis
}