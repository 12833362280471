import { createTheme } from '@mui/material/styles'
import { red, orange, blue, green } from '@mui/material/colors'
import React from 'react'

export type SentimentColors = {
  good: string
  average: string
  poor: string
  attention: string  
}

export const sentimentColors: SentimentColors = {
  good: '#4caf50',
  average: '#f2e663',
  poor: '#f44336',
  attention: '#ff9800'
}

export const wizardOfOz = {
  main: '#F7F903',
  empty: '#ffffff30'
}

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }
  interface TypographyVariants {
    linkText: React.CSSProperties;
    errorText: React.CSSProperties;
    actionText: React.CSSProperties;
    teamText: React.CSSProperties;
    mentionText: React.CSSProperties;
    emphasize: React.CSSProperties;
    breadcrumbText: React.CSSProperties;
    formInputText: React.CSSProperties;
    body3: React.CSSProperties;
    subtitle3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    linkText?: React.CSSProperties;
    errorText?: React.CSSProperties;
    actionText?: React.CSSProperties;
    teamText?: React.CSSProperties;
    mentionText?: React.CSSProperties;
    emphasize?: React.CSSProperties;
    breadcrumbText?: React.CSSProperties;
    formInputText?: React.CSSProperties;
    body3?: React.CSSProperties;
    subtitle3?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    linkText: true;
    errorText: true;
    actionText: true;
    teamText: true;
    mentionText: true;
    emphasize: true;
    breadcrumbText: true;
    formInputText: true;
    body3: true;
    subtitle3: true;
  }
}

declare module '@mui/material/ListItem' {
  interface ListItemComponentsPropsOverrides {
    variant: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

// Create a theme instance.
const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#303030',
      paper: '#424242'
    },
    primary: {
      // main: '#2A9FAE',
      main: '#B977F2',
      light: '#33C4DC',
      dark: '#1E676B'
    },
    secondary: {
      main: '#AE2A9F',
      light: '#C86ABB',
      dark: '#7F1A8A'
    },
    neutral: {
      main: '#707070',
      dark: '#707070',
      light: '#707070',
    },
    text: {
      primary: '#ffffff',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)'
    },
    action: {
      disabled: 'rgba(255, 255, 255, 0.3)',
      hover: 'rgba(255, 255, 255, 0.08)',
      selected: 'rgba(255, 255, 255, 0.16)'
    },
    error: {
      main: red[500],
      light: red[300],
      dark: red[700]
    },
    warning: {
      main: '#ff9800',
      light: orange[300],
      dark: '#ff9800'
    },
    info: {
      main: blue[500],
      light: blue[300],
      dark: blue[700]
    },
    success: {
      main: green[500],
      light: green[300],
      dark: green[700]
    }
  },
  typography: {
    fontFamily: [ 'Poppins', 'sans-serif' ].join(','),
    h1: {
      fontSize: '28px',
      textTransform: 'uppercase',
      fontWeight: 'bold'
    },
    h2: {
      fontSize: '21px',
      textTransform: 'uppercase',
      fontWeight: 'bold'
    },
    h3: {
      fontSize: '16px',
      textTransform: 'uppercase',
      fontWeight: 'bold'
    },
    h4: {
      fontSize: '34px',
    },
    h5: {
      fontSize: '24px',
    },
    h6: {
      fontSize: '20px',
      fontWeight: 'medium'
    },
    body2: {
      fontSize: '12px',
      fontWeight: 'medium'
    },
    overline: {
      fontSize: '12px',
      fontWeight: 'bold'
    },
    emphasize: {
      fontSize: '12px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      color: '#ffffff80',
      lineHeight: '12px',
      display: 'inline-block'
    },
    linkText: {
      color: orange[500],
      fontSize: '12px',
      lineHeight: '12px',
      display: 'inline-block'
    },
    errorText: {
      color: '#f44336',
      fontSize: '12px',
    },
    teamText: {
      color: '#33c4dc',
      fontSize: '12px',
      lineHeight: '12px',
      display: 'inline-block'
    },
    mentionText: {
      color: '#33c4dc',
      fontSize: '16px',
      lineHeight: '12px',
      display: 'inline-block'
    },
    actionText: {
      color: 'rgba(255, 255, 255, 0.7)',
      fontSize: '12px',
      lineHeight: '14px',
      display: 'inline-block'
    },
    formInputText: {
      color: '#ffffffb3',
      fontSize: '12px',
      lineHeight: '12px',
      display: 'inline-block'
    },
    subtitle3: {
      color: '#f2e663',
      textTransform: 'uppercase',
      fontSize: '12px',
      fontWeight: 'bold',
      display: 'inline-block'
    },
    body3: {
      color: '#ffffffb3',
      fontSize: '14px',
      // lineHeight: '14px',
      display: 'inline-block'
    },
    breadcrumbText: {
      fontSize: '16px'
    },
  },
  spacing: 16,
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFilledInput-root':{
            borderRadius: '4px',
            backgroundColor: '#5E5E5E'
          },
          '& .MuiFilledInput-root::before, .MuiFilledInput-root:hover:not(.Mui-disabled)::before, .MuiFilledInput-root::after':{
            border: 'none'
          },
          '& .MuiFilledInput-input': {
            padding: '6px 16px'
          },
          '& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-filled': {
            margin: '0 !important' // 🤮
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '4px'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        filled: {
          padding: '6px 16px'
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          border: 'none',
          '&.MuiDataGrid-columnHeader:focus, &.MuiDataGrid-cell:focus': {
            outline: 'none',
          }
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        outlinedError: {
          borderColor: '#f44336',
          color: '#f44336',
        },
        filledWarning: {
          color: '#ffffff',
          fontSize: '14px'
        },
        filledInfo: {
          color: '#ffffff',
          fontSize: '14px'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#424242',
          backgroundImage: 'none'
        }
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#424242',
          backgroundImage: 'none'
        }
      }
    },
    MuiMobileStepper: {
      styleOverrides: {
        root: {
          backgroundColor: '#424242',
          backgroundImage: 'none'
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          ['&.Mui-selected']: {
            backgroundColor: 'rgba(255, 255, 255, 0.08)'
          },
          ['&.Mui-selected:hover']: {
            backgroundColor: 'rgba(255, 255, 255, 0.08)'
          },
          ['&:hover']: {
            backgroundColor: '#3C3C3C'
          }
        }
      },
      variants: [
        {
          props: { color: 'quiet' },
          style: {
            ['&:hover']: {
              cursor: 'auto',
              backgroundColor: '#424242'
            }
          }
        },
        {
          props: { color: 'subtle' },
          style: {
            ['&:hover']: {
              backgroundColor: '#424242'
            }
          }
        }
      ]
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#B977F2',
          ['&.Mui-checked']: {
            color: '#B977F2'
          }
        }
      },
    }
  }
})

export default theme
