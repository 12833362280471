export default function loadteamApi(api: Prime.ApiRequest) {

  // Get All User Teams
  const getTeams = async (orgId?: number): Promise<Prime.Team[]> => {
    return api(`/api/teams${orgId ? `?organizationId=${orgId}` : ''}`)
  }
  
  // Get Team 
  const getTeam = (id: string | number): Promise<Prime.Team> => {
    return api(`/api/teams/${id}`)
  }
  
  // Create a User Team
  const createTeam = (team: Prime.Team): Promise<Prime.Team> => {
    return api('/api/teams', team)
  }
  
  // Update a User Team
  const updateTeam = (id: number, team: Prime.Team): Promise<Prime.Team> => {
    return api(`/api/teams/${id}`, team, { method: 'PUT' })
  }
  
  // Archive a User Team
  const archiveTeam = (id: number): Promise<Prime.Team> => {
    return api(`/api/teams/${id}`, {}, { method: 'DELETE' })
  }
  
  // Team Users
  const getTeamUsers = async (teamId: number, excludeSelf: boolean): Promise<Prime.TeamCollaborator[]> => {
    return api(`/api/teams/${teamId}/members/${excludeSelf}`)
  }
  
  // Team Invites
  const getTeamInvites = (teamId: number): Promise<Prime.TeamCollaborator[]> => {
    return api(`/api/teams/${teamId}/invites`)
  }
  
  // Invite Collaborator
  const inviteTeamCollaborators = (teamId: number, builders: Prime.InviteCollaborator[]) => {
    return api(`/api/teams/${teamId}/invites`, {
      builders
    })
  }
  
  // Delete Collaborator
  const deleteMemberFromTeam = (teamId: number, memberId: number) => {
    return api(`/api/teams/${teamId}/members/${memberId}`, {}, { method: 'DELETE' })
  }
  
  // Delete Collaborator
  const deleteMemberInviteFromTeam = (teamId: number, teamInviteId: number) => {
    return api(`/api/teams/${teamId}/invites/${teamInviteId}`, {}, { method: 'DELETE' })
  }
  
  // Issue Reward To Collaborator From Owner
  const issueRewardToCollaborator = (teamId: number | number, memberId: number, rewardAmount: number, rewardMsg: string, issuerType: string, rewardCategoryId: number): Promise<void> => {
    return api(`/api/teams/${teamId}/reward/${issuerType}`, {
      toMemberId: memberId,
      amount: rewardAmount,
      message: rewardMsg,
      rewardCategoryId: rewardCategoryId
    })
  }

  // Leaderboard
  const getLeaderboard = (teamId: number, received: boolean, categoryId: number): Promise<Prime.TeamLeaderboard[]> => {
    return api(`/api/teams/${teamId}/leaderboard?received=${received}&categoryId=${categoryId}`)
  }
  
  // Trends
  const getTeamTrends = (teamId: number, days: number = 30, timeZoneId: string): Promise<Prime.TeamRewardTrend[]> => {
    return api(`/api/teams/${teamId}/trends/${days}?timeZoneId=${timeZoneId}`)
  }

  const getTeamCategoryTrends = (teamId: number, startDate: string, endDate: string, timeZoneId: string): Promise<Prime.TeamCategoryTrends> => {
    return api(`/api/teams/${teamId}/category-trends?timeZoneId=${timeZoneId}&startDate=${startDate}&endDate=${endDate}`)
  }
  
  // Recent Activity
  const getTeamActivty = (teamId: number): Promise<Prime.TeamActivity[]> => {
    return api(`/api/teams/${teamId}/recent-activity`)
  }

  // Auth Team Integration
  const authTeamIntegration = (teamId: number, code: string, redirectUri: string, type: string): Promise<void> => {
    return api(`/api/teams/${teamId}/integrations/install/${type}`, {
      slackCode: code,
      redirectUrl: redirectUri
    })
  }

  // Connect Team Integration
  const connectAdminTeamIntegration = (teamId: number, code: string, redirectUri: string, type: string): Promise<void> => {
    return api(`/api/teams/${teamId}/integrations/${type}`, {
      slackCode: code,
      redirectUrl: redirectUri
    })
  }

  // Connect Member Integration
  const connectTeamIntegration = (teamId: number, code: string, redirectUri: string, type: string): Promise<void> => {
    return api(`/api/teams/${teamId}/members/integrations/${type}`, {
      slackCode: code,
      redirectUrl: redirectUri
    })
  }

  // Disconnect Team Integration
  const disconnectTeamIntegration = (id: number, type: string): Promise<void> => {
    return api(`/api/teams/${id}/integrations/${type}`, {}, { method: 'DELETE' })
  }

  // Disconnect Member Integration
  const disconnectMemberIntegration = (id: number, type: string): Promise<void> => {
    return api(`/api/teams/${id}/members/integrations/${type}`, {}, { method: 'DELETE' })
  }

  // Get Member Team Integrations
  const getMemberTeamIntegrations = (teamId: number): Promise<Prime.Integration[]> => {
    return api(`/api/teams/${teamId}/members/integrations`)
  }

  // Get Team Integrations
  const getTeamIntegrations = (teamId: number): Promise<Prime.TeamIntegrations> => {
    return api(`/api/teams/${teamId}/integrations`)
  }
  
  // Member's Team Allotment
  const getMembersTeamAllotment = (teamId: number): Promise<Prime.TeamAllotment> => {
    return api(`/api/teams/${teamId}/members/allotment`)
  }

  // Member's Team Rewards
  const getMembersTeamRewards = (teamId: number, startDate: string, endDate: string): Promise<Prime.TeamDailyReward> => {
    return api(`/api/teams/${teamId}/rewards-today?startDate=${startDate}&endDate=${endDate}`)
  }

  const getTeamRoles = async (teamId: number): Promise<Prime.TeamRole[]> => {
    return api(`/api/teams/${teamId}/roles`)
  }

  const updateMemberTeamRole = (teamId: number, memberId: number, roleId: number): Promise<void> => {
    return api(`/api/teams/${teamId}/members/${memberId}/roles/${roleId}`, {}, { method: 'PUT' })
  }

  // Pulse Feeback
  const getPulseQuestions = (teamId: number, numberOfQuestions?: number): Promise<Prime.PulseQuestion[]> => {
    return api(`/api/team/${teamId}/pulse/questions?numOfQuestions=${numberOfQuestions ? numberOfQuestions : 1}`)
  }

  const savePulseAnswers = (teamId: number, answers: Prime.PulseResponse[]): Promise<void> => {
    return api(`/api/team/${teamId}/pulse/responses`, answers )
  }

  const getPulseDates = (teamId: number): Promise<Prime.PulseDates> => {
    return api(`/api/team/${teamId}/pulse/dates`)
  }

  //Cashout
  const getCashoutPeriods = (teamId: number): Promise<Prime.CashoutPeriod[]> => {
    return api(`/api/teams/${teamId}/cash-outs`)
  }
  const getCashoutById = (teamId: number, cashoutId: number): Promise<Prime.Cashout> => {
    return api(`/api/teams/${teamId}/cash-outs/${cashoutId}`)
  }
  const generateCashout = (teamId: number, startDate: Date, endDate: Date): Promise<Prime.CashoutTransaction[]> => {
    return api(`/api/teams/${teamId}/cash-outs/transactions?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`)
  }
  const saveCashout = (teamId: number, startDate: Date, endDate: Date, memberBonuses: Prime.CashoutBonus[], cashoutStatusId: number = 1) => {
    return api(`/api/teams/${teamId}/cash-outs/`, {
      startDate,
      endDate,
      cashoutStatusId,
      memberBonuses
    })
  }
  const updateCashout = (teamId: number, cashoutId: number, transactions: Prime.CashoutBonus[], cashoutStatusId: number = 1) => {
    return api(`/api/teams/${teamId}/cash-outs/${cashoutId}`, {
      cashoutStatusId,
      memberBonuses: transactions
    }, { method: 'PUT' })
  }

  const generateTeamLink = (teamId: number) => {
    return api(`/api/teams/${teamId}/invites/code`, {}, { method: 'POST' })
  }

  const getTeamLink = (teamId: number) => {
    return api(`/api/teams/${teamId}/invites/code`)
  }
  
  const consumeTeamLink = (code: string) => {
    return api('/api/members/invites/code', { code })
  }
  
  const updateMemberCashoutAvailability = (teamId: number, memberId: number, isExcludedFromCashOut: boolean) => {
    return api(`/api/teams/${teamId}/members/${memberId}/cash-outs`, {
      isExcludedFromCashOut
    }, { method: 'PUT' })
  }

  const teamCycleTime = (teamId: number) => {
    return api(`/api/teams/${teamId}/cycle-time`)
  }

  const apis = {
    getTeams,
    getTeam,
    createTeam,
    updateTeam,
    archiveTeam,
    getTeamUsers,
    getTeamInvites,
    inviteTeamCollaborators,
    deleteMemberFromTeam,
    deleteMemberInviteFromTeam,
    issueRewardToCollaborator,
    getLeaderboard,
    getTeamTrends,
    getTeamCategoryTrends,
    getTeamActivty,
    connectTeamIntegration,
    connectAdminTeamIntegration,
    authTeamIntegration,
    disconnectTeamIntegration,
    disconnectMemberIntegration,
    getMemberTeamIntegrations,
    getTeamIntegrations,
    getMembersTeamAllotment,
    getMembersTeamRewards,
    getTeamRoles,
    updateMemberTeamRole,
    getPulseQuestions,
    savePulseAnswers,
    getPulseDates,
    getCashoutPeriods,
    getCashoutById,
    generateCashout,
    saveCashout,
    updateCashout,
    generateTeamLink,
    getTeamLink,
    consumeTeamLink,
    updateMemberCashoutAvailability,
    teamCycleTime
  }
  
  return apis
}
