import { makeAutoObservable } from 'mobx'
import React from 'react'
import { components, features, actions, ComponentsType, ActionType, RoleHierarchy, FeatureType } from '../utils/rolesManifest'

export class PermissionsStore {
  teamRole: Prime.Role = 'None'

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  setRole(roles: Prime.Role[], isOrg: boolean = false) {
    roles.sort(( a, b ) => {
      return RoleHierarchy.indexOf(a) - RoleHierarchy.indexOf(b)
    })
    this.teamRole = `${isOrg ? 'Org' : ''}${roles[0]}` as Prime.Role
  }

  reset(){
    this.teamRole = 'None'
  }

  hasFeature(featureName: keyof FeatureType) {
    return features[featureName].indexOf(this.teamRole) >= 0
  }

  canRender(componentName: keyof ComponentsType, component: React.ReactElement) {
    if(!components[componentName]){
      return component
    }
    return components[componentName].indexOf(this.teamRole) >= 0 ? component : null
  }

  availableAction(action: keyof ActionType) {
    return actions[action][this.teamRole]
  }
}

export default new PermissionsStore()