import React, { useContext } from 'react'
import SnackBarInstance, { SnackbarStore } from './snackBarStore'
import GlobalInstance, { GlobalStore } from './globalStore'
import PermissionStoreInstance, { PermissionsStore } from './permissionsStore'
import { api } from '../utils/api/api'
import apis from '../utils/api'
import environment from '../utils/environment'
import auth from '../utils/auth'

const {
  primeUrl
}  = environment as Prime.RuntimeEnvironment

const initializedApi = api(primeUrl)

interface RootContextType {
  SnackbarStore: SnackbarStore
  GlobalStore: GlobalStore
  PermissionsStore: PermissionsStore
  EnvVarStore: Prime.RuntimeEnvironment
  api: Prime.Api,
  auth: any
}

const initialContext = {
  SnackbarStore: {} as SnackbarStore,
  GlobalStore: {} as GlobalStore,
  PermissionsStore: {} as PermissionsStore,
  EnvVarStore: environment,
  api: apis(initializedApi),
  auth: {}
}

export const AppContext = React.createContext<RootContextType>(initialContext)

export const useGlobal = () => useContext(AppContext).GlobalStore
export const useSnackbar = () => useContext(AppContext).SnackbarStore
export const usePermissions = () => useContext(AppContext).PermissionsStore
export const useEnvVar = () => useContext(AppContext).EnvVarStore
export const useApi = () => useContext(AppContext).api
export const useAuth = () => useContext(AppContext).auth

type Props = {
  children: React.ReactNode
  session?: any
}
export default function AppContextComponent({ children, session }: Props) {
  return (
    <AppContext.Provider
      value={{
        SnackbarStore: SnackBarInstance,
        GlobalStore: GlobalInstance,
        PermissionsStore: PermissionStoreInstance,
        EnvVarStore: initialContext.EnvVarStore,
        api: initialContext.api,
        auth: auth(initialContext.api, session)
      }}>
      {children}
    </AppContext.Provider>
  )
}