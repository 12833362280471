export const RoleHierarchy: Prime.Role[] = [ 'Owner', 'Admin', 'Stakeholder', 'Builder' ]

export interface ComponentsType {
  TeamSettingsButton: Prime.Role[]
  TeamPayoutTab: Prime.Role[]
  TeamSettings: Prime.Role[]
  TeamCashout: Prime.Role[]
  EditRoleButton: Prime.Role[]
}

export const components: ComponentsType = {
  TeamSettingsButton: [ 'Owner', 'Admin' ],
  TeamPayoutTab: [ 'Owner', 'Admin' ],
  TeamSettings: [ 'Owner', 'Admin' ],
  TeamCashout: [ 'Owner', 'Admin' ],
  EditRoleButton: [ 'Owner', 'Admin' ]
}

export interface FeatureType {
  GoalStatusButton: Prime.Role[]
  HideTeamAlerts: Prime.Role[]
  HidePulseSurvey: Prime.Role[]
  alwaysShowPulse: Prime.Role[]
}

export const features: FeatureType = {
  GoalStatusButton: [ 'Owner', 'Admin', 'Stakeholder' ],
  HideTeamAlerts: [ 'OrgAdmin', 'OrgStakeholder' ],
  HidePulseSurvey: [ 'OrgAdmin', 'OrgStakeholder' ],
  alwaysShowPulse: [ 'OrgAdmin', 'OrgStakeholder', 'Owner', 'Admin', 'Stakeholder' ]
}

export type IssueRewardType = 'IssueAdminReward' | 'IssueReward'
export type TabOption = 'home' | 'pulse' | 'goals' | 'members' | 'connect' | 'settings' | 'cashout' | 'rewards' | ''

export interface ActionType {
  IssueReward: Partial<Record<Prime.Role, IssueRewardType>>
  AllotmentTeamTabs: Partial<Record<Prime.Role, TabOption[]>>
  SliceOfPieTeamTabs: Partial<Record<Prime.Role, TabOption[]>>
}

const basicTabs: TabOption[] = [ 'home', 'pulse', 'goals', 'rewards', 'members', 'connect' ]
const basicOrgTabs: TabOption[] = [ 'home', 'pulse', 'goals', 'rewards', 'members' ]

export const actions: ActionType = {
  IssueReward: {
    Admin: 'IssueAdminReward',
    Owner: 'IssueAdminReward',
    Builder: 'IssueReward'
  },
  AllotmentTeamTabs: {
    Owner: [ ... basicTabs, 'settings' ],
    Admin: [ ... basicTabs, 'settings' ],
    OrgAdmin: basicOrgTabs,
    OrgStakeholder: basicOrgTabs,
    Stakeholder: basicTabs,
    Builder: basicTabs,
  },
  SliceOfPieTeamTabs: {
    Owner: [ ... basicTabs, 'cashout', 'settings' ],
    Admin: [ ... basicTabs, 'cashout', 'settings' ],
    OrgAdmin: basicOrgTabs,
    OrgStakeholder: basicOrgTabs,
    Stakeholder: [ ... basicTabs, 'cashout' ],
    Builder: basicTabs,
  }
}