
export default function loadAccountApi(api: Prime.ApiRequest) {

  const updateAccountAvatarUrl = (avatar: string): Promise<void> => {
    return api('/api/members/settings/avatar', { avatarUrl: avatar }, { method: 'PUT' })
  }

  const updateAccountSettings = (displayName: string, optOutOfAllAlerts: boolean): Promise<void> => {
    return api('/api/members/settings', { displayName: displayName, optOutOfAllAlerts: optOutOfAllAlerts }, { method: 'PUT' })
  }
  
  const apis = {
    updateAccountAvatarUrl,
    updateAccountSettings
  }
  
  return apis
}