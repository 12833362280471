import internal from 'stream'

export default function loadSlackApi(api: Prime.ApiRequest){

  const getRewardCategories = (): Promise<Prime.RewardCategory[]> => {
    return api('/api/rewards/categories')
  }

  const getRewardTeamMembers = (): Promise<Prime.TeamMember[]> => {
    return api('/api/rewards/teams/members')
  }

  const rewardTeamMembers = (rewards: Prime.RewardTransaction[]): Promise<void> => {
    return api('/api/rewards/member', { rewards })
  }

  const saveRewardReaction = (rewardTransactionId: number, reaction: string): Promise<Prime.TeamActivityReaction> => {
    return api('/api/rewards/reaction', { rewardTransactionId, reaction })
  }

  const deleteRewardReaction = (reactionId: number): Promise<void> => {
    return api(`/api/rewards/reaction/${reactionId}`, {}, { method: 'DELETE' })
  }

  const checkLastRewardSent = (teamId: number, memberId: number): Promise<Prime.LastRewardSent> => {
    return api(`/api/rewards/teams/${teamId}/members/${memberId}/date`)
  }
  
  const apis = {
    getRewardCategories,
    getRewardTeamMembers,
    rewardTeamMembers,
    saveRewardReaction,
    deleteRewardReaction,
    checkLastRewardSent
  }

  return apis
}
