import SnackBarInstance from '../../stores/snackBarStore'
import { getToken, logout } from '../../utils/auth'

interface PrimeHeaders {
  'Content-Type': string
  Authorization?: string
}

export class HttpError extends Error {
  code: number

  constructor(message: string, code: number = 500) {
    super(message)
    this.code = code
  }
}

export const api = (baseUrl: string) => async (endpoint: string, body?: any, { auth, method, hideSnackbar }: Prime.ApiOptions = {}) =>{
  let currentToken = auth
  if(!auth){
    currentToken = await getToken()
  }
  const { showSnackBar } = SnackBarInstance
  const headers: PrimeHeaders = {
    'Content-Type': 'application/json',
  }
  if (currentToken) {
    headers.Authorization = `Bearer ${currentToken}`
  }

  const finalMethod = method || (body ? 'POST' : 'GET')
  const configs = {
    method: finalMethod,
    headers,
    ...((body && finalMethod !== 'GET') && { body: JSON.stringify(body) })
  }


  return fetch(`${baseUrl}${endpoint}`, configs)
    .then(async res => {
      if (res.status === 401) {
        logout('Session Expired')
        return
      }
      if (res.ok) {
        try {
          return await res.json()
        } catch (e) {
          return
        }
      } else {
        const errorMessage = await res.text()
        hideSnackbar || showSnackBar(errorMessage, 'error')
        return Promise.reject(new HttpError(errorMessage, res.status))
      }
    })
}