const staging: Prime.RuntimeEnvironment = {
  firebaseApiKey: 'AIzaSyDj6Yjlf9tfwmz9EF0eLHVWAZrZ8wwXgXc',
  firebaseAuthDomain: 'nerdunited-stage.firebaseapp.com',
  firebaseProjectId: 'nerdunited-stage',
  firebaseAppId: '1:650014097264:web:fcf38c512e2273503959b5',
  primeUrl: 'https://api.stage.nerdunited.com/default',
  recaptchaSiteKey: '6Lc0Kq4hAAAAAKawFbYCjtgpa0tgAU5BNbWeodUx',
  assetsUrl: 'https://download.stage.nerdunited.com',
  slackRedirectUri: 'https://stage.prime.nerdunited.com/teams/integrations/slack',
  slackClientId: '4005316036640.3971269069207',
  sentryDsn: 'https://0456116137cc4716bc993653a5de37eb@o1344175.ingest.sentry.io/6619834',
  sentrySampling: 1.0,
  analyticsKey: 'G-SBC7VZVN56',
  pendoKey: '',
  zendeskKey: 'ed339b40-aa30-489d-b8b0-b97e83de11e9',
  launchDarklyClientId: '632a28bdad7e18114f237ad0'
}
export default staging