import { makeAutoObservable } from 'mobx'

type SnackbarType = 'error' | 'success' | 'warning'

export class SnackbarStore {
  show = false
  message = ''
  type: SnackbarType = 'success'

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  showSnackBar(message: string, type: SnackbarType) {
    this.message = message
    this.type = type
    this.show = true
  }

  hideSnackBar() {
    this.message = ''
    this.show = false
  }
}

export default new SnackbarStore()