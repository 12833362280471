export default function loadAgreementApi(api: Prime.ApiRequest) {

  // Terms of Service Agreements
  const getUnsignedAgreements = (): Promise<Prime.PrimeAgreement> => {
    return api('/api/agreements/unsigned')
  }
  
  const postAgreements = (agreementId: number) => {
    return api(`/api/agreements/${agreementId}`, {}, { method: 'POST' })
  }

  const apis = {
    getUnsignedAgreements,
    postAgreements
  }
  return apis
}