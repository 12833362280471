import logger from '../logger'



async function showAppVersion() {
  const versionInfo = await fetch('/version', {
    method: 'GET'
  })

  const version = await versionInfo.text()
  logger.info(`Prime Version: ${version}`)
  console.log(`Prime Version: ${version}`)
}

async function logNerdLogo() {
  console.log(`%c
                          .:^~!77.           :!5!
                  :~7J5GB#&&@@&7      .:~?5G&@@#.
        .7:   :75B&@@@@@@@@@@&J7?J5PGB&@@@@@@@@@!
        ?#&. ~P&@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@7
    . P@@#.Y@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@&.     .
      P@@@&P@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@J^!?YG#?
    :@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@&@@@@@P
    :&@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@5
      5@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@&J:^77
^Y!: .G@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@&B&@#^
  7@@#GG@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@5.
  ^5&@@@@@@@&JY&@@@@@@@@#&@@@@@@@@@@@@@@@@@@@@@@@@&5~
    :75B&@@G^  :?G&@@@@@&Y?JPB&@@@@@@@@@@&#GJ!B#57:
        .^~       .~7YPGB#G7. :^~!77?77!^:    .
    .:...................  ...    ...................:.
    ~@&&&&&&&&&&&&&&&&&&&B^     .G&&&&&&&&&&&&&&&&&&&@?
    .YB@@&?!7777!!!!!7!B@@577777J@@#77!!!!!777777#@@#5:
    :&@#      ^JYJ^  Y@@@@@@@@@@@G  :JYJ~      G@@~
    :&@#.    ~@@@@@! 5@@P7!~!!Y@@B :@@@@@?     G@@~
    :&@#.    .5#&#5. 5@@!     ^@@B  Y#&&P^     G@@~
    :&@#: ...  :::   5@@7     ^@@B.  .::  .....G@@~
    .#@@&#&&&&#####&&@@@!     ^@@@&#&####&&&&#&@@@^
      ~?JJJJJJJJJJJJJJJJ!       ~JJJJJJJJJJJJJJJJJ!
`, 'color: #33C4DC')
  console.log(`%c
!????7        ^????!  !????????????~ ^??????????7~:     !?????????7!^.
G@@@@@G^      J@@@@#  B@@@@@@@@@@@@P Y@@@@@@@@@@@@&BJ.  G@@@@@@@@@@@@&GJ^
G@@@@@@&7     J@@@@B  B@@@@BYYYYYYY! J@@@@&5555G&@@@@B: G@@@@#PPPGB&@@@@@P:
G@@@@@@@@P:   J@@@@B  B@@@@J         J@@@@B     ^&@@@@? G@@@@5     :!B@@@@#^
G@@@@&&@@@#!  J@@@@B  B@@@@G777777~  J@@@@B    .7@@@@@! G@@@@5       .B@@@@P
G@@@@Y:G@@@@Y ?@@@@B  B@@@@@@@@@@@G  J@@@@@#BBB#@@@@&7  G@@@@5        J@@@@&.
G@@@@5  J@@@@BG@@@@B  B@@@@BJYYYYY!  J@@@@@#@@@@@&Y!.   G@@@@5        P@@@@B
G@@@@5   ~B@@@@@@@@B  B@@@@J         J@@@@B ~&@@@@Y     G@@@@Y      :Y@@@@@!
G@@@@5    .5@@@@@@@B  B@@@@P~~~~~~!: J@@@@B  ^B@@@@G:   G@@@@B7???YG&@@@@#!
G@@@@5      !#@@@@@#  B@@@@@@@@@@@@5 Y@@@@#   .P@@@@&!  G@@@@@@@@@@@@@@B?. 
JPPPP7       :5GPPPY  JPPPPPGGGGPPP7 7GPPPY     YGGGGP: JPPPPGGPPP5Y?!:    
`, 'color: #C86ABB')
  console.log(`%c
!?????JJ??!^    7????????7!:    ~????. 7J??J7          7????7 .?????????JJ~
G@@@@@@@@@@@B? :@@@@@@@@@@@@B?  5@@@@: #@@@@@J        J@@@@@# ^@@@@@@@@@@@5
G@@@&7!!75@@@@Y:&@@@B!!!7P@@@@Y 5@@@@: #@@@@@@7      7@@@@@@B ^@@@@P~!!!!!:
G@@@#     #@@@#:&@@@5    .&@@@B 5@@@@: #@@@@@@@~    ~@@@@@@@B ^@@@@J       
G@@@&JJJYB@@@@?.&@@@B???JG@@@&! 5@@@@: #@@@&#@@&^  ^&@@#&@@@B ^@@@@&&&&&&P 
G@@@@@@@@@&#5~ :&@@@@@@@@@@GJ:  5@@@@: #@@@G^&@@#:.#@@&^B@@@B ^@@@@#GGGGGY 
G@@@&!~~~^:.   :&@@@P:P@@@@7    5@@@@: #@@@G !@@@BB@@@~ B@@@B ^@@@@J       
G@@@#.         :&@@@5  Y@@@@Y   5@@@@: #@@@G  J@@@@@@?  B@@@B ^@@@@P!!!!!!^
G@@@&.         :@@@@P   7@@@@G: 5@@@@: #@@@B   5@@@@5   B@@@# ^@@@@@@@@@@@5
!JJJ?          .?JJJ!    ~JJJJ~ ~JJJ?. 7JJJ!   .?JJ?.   7JJJ7 .?JJJJJJJJJJ~
`, 'color: #33C4DC')
}

export async function beginLogs() {
  logNerdLogo()
  await showAppVersion()
}