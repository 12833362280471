export default function loadFeatureIdeaApi(api: Prime.ApiRequest){

  const getFeatureIdeas = (status: number = 1, sort: string = 'New'): Promise<Prime.FeatureIdea[]> => {
    return api(`/api/feature-ideas?status=${status}&sort=${sort}`)
  }

  const createFeatureIdea = (title: string, description: string): Promise<void> => {
    return api('/api/feature-ideas', {
      title,
      description
    })
  }

  const voteFeatureIdea = (featureIdeaId: number): Promise<void> => {
    return api(`/api/feature-ideas/${featureIdeaId}/vote`, {}, { method: 'POST' })
  }
  
  const unvoteFeatureIdea = (featureIdeaId: number): Promise<void> => {
    return api(`/api/feature-ideas/${featureIdeaId}/vote`, {}, { method: 'DELETE' })
  }

  const getFeatureIdea = (featureIdeaId: number): Promise<Prime.FeatureIdea> => {
    return api(`/api/feature-ideas/${featureIdeaId}`)
  }

  const createFeatureIdeaReply = (featureIdeaId: number, reply: string): Promise<void> => {
    return api(`/api/feature-ideas/${featureIdeaId}/reply`, { featureIdeaId, reply }, { method: 'POST' })
  }

  const updateFeatureIdeaReply = (featureIdeaId: number, reply: string): Promise<void> => {
    return api(`/api/feature-ideas/${featureIdeaId}/reply`, { reply }, { method: 'PUT' })
  }
  
  const apis = {
    getFeatureIdeas,
    createFeatureIdea,
    voteFeatureIdea,
    unvoteFeatureIdea,
    getFeatureIdea,
    createFeatureIdeaReply,
    updateFeatureIdeaReply
  }

  return apis
}
